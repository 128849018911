import React, { useMemo } from 'react'
import styled from 'styled-components'
import htmlToReact from '../utils/htmlToReact'

const Container = styled.div`
  padding: 21px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 26px 24px;
  }
`

const Child = styled.div`
  overflow: auto;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  margin-top: 0;
  margin-bottom: 8pt;
  margin-right: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 16px;
  }

  ul {
    list-style: inside;
  }
  li {
    margin-left: 7.1pt;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }

  p:not(:last-child) {
    margin-bottom: 20px;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    margin-top: 2pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 12pt;
    font-weight: bold;
  }

  h2 {
    margin-top: 2pt;
    margin-left: 14.2pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
    font-weight: bold;
  }

  h3 {
    margin-top: 2pt;
    margin-left: 28.35pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    font-size: 11pt;
    color: #1f3763;
  }

  h4 {
    margin-top: 2pt;
    margin-left: 42.6pt;
    margin-bottom: 10pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    color: #2f5496;
  }
  
  // Appelé dans les Cookies en class de h3 - terms.ts
  .subtitle {
    font-weight: 600;
    margin-left: 0;
  }
`

interface TermsContentProps {
  content: string
}

const TermsContent: React.FC<TermsContentProps> = ({ content: contentStr }) => {
  const content = useMemo(() => htmlToReact(contentStr), [contentStr])

  return (
    <Container>
      <Child>{content}</Child>
    </Container>
  )
}

export default TermsContent
