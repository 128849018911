import termsLinks from './termsLinks'

export type MenuItem = {
    url: string
    label: string
}

type Item = MenuItem & {
    content: string
    title?: string
    meta_desc?: string
}

const terms: Item[] = [
    {
        url: termsLinks.cgv,
        label: 'Conditions Générales de prestations et de vente',
        meta_desc: 'Conditions Générales de prestations et de vente',
        content: `
    <p><strong>LES PRESENTES CONDITIONS GENERALES de PRESTATIONS ET DE VENTE (&laquo;</strong></p>
<p><strong>CGPV &raquo;) SONT ETABLIES ENTRE :</strong></p>
<ul>
<li>la&nbsp;<strong>soci&eacute;t&eacute; SAVE STORE</strong>, SAS au capital de 1.440.000 euros, inscrite au RCS de Nanterre sous le num&eacute;ro 490 218 161, Immeuble Colis&eacute;e Gardens-Zac Danton, 8-14, avenue de l&rsquo;Arche, 92400 Courbevoie, num&eacute;ro TVA FR 53 490 218 161 agissant tant pour son compte que pour celui des magasins franchis&eacute;s sous l&rsquo;enseigne SAVE ou Point Services Mobiles situ&eacute;s en France m&eacute;tropolitaine (ci-apr&egrave;s &laquo; les Magasins &raquo;), ci-apr&egrave;s &laquo; SAVE &raquo; ou &laquo;&nbsp;<strong>le Vendeur&nbsp;</strong>&raquo; (en cas de vente) ou &laquo;&nbsp;<strong>le R&eacute;parateur&nbsp;</strong>&raquo; (en cas de Prestation),&nbsp;<strong><em>d&rsquo;une part</em></strong>,</li>
<li>et la personne physique ou morale qui commande une Prestation ou le consommateur qui ach&egrave;te un Appareil ou un Produit au Vendeur, ci-apr&egrave;s</li>
</ul>
<p>&laquo;&nbsp;<strong>l&rsquo;Acheteur&nbsp;</strong>&raquo; (en cas de vente) ou &laquo; le&nbsp;<strong>Client&nbsp;</strong>&raquo; (en cas de vente ou de Prestation),&nbsp;<strong><em>d&rsquo;autre part,</em></strong></p>
<p><strong>IL A ETE EXPOSE ET CONVENU CE QUI SUIT :</strong></p>
<h1> I.      Objet</h1>
<p>Les CGPV ont pour objet de d&eacute;finir les conditions dans lesquelles SAVE propose au Client :</p>

<ul>
<li>une offre de Prestations de Service &agrave; la demande ;</li>
<li>une offre de Prestations de Service apr&egrave;s-vente constitu&eacute;e de Prestations r&eacute;alis&eacute;es par SAVE ou ex&eacute;cut&eacute;es dans les Centres de r&eacute;parations agr&eacute;&eacute;s sur les appareils de t&eacute;l&eacute;phonie mobiles (ci-apr&egrave;s</li>
</ul>
<p>&laquo; l&rsquo;Appareil &raquo; et &laquo; les Appareils &raquo;) ;</p>
<ul>
<li>la vente de produits neufs, d&rsquo;occasions ou reconditionn&eacute;s : appareils de t&eacute;l&eacute;phonie mobiles, tablettes, accessoires, guides, pc, consoles, produits d&eacute;riv&eacute;s et autre fourniture de contenus ou services num&eacute;riques (ci-apr&egrave;s &laquo; le Produit &raquo; ou</li>
</ul>
<p>&laquo; les Produits &raquo;).</p>
<p>L&rsquo;offre de Prestations &agrave; la demande et l&rsquo;offre de Prestations de Service apr&egrave;s-vente sont d&eacute;nomm&eacute;es ensemble ci-apr&egrave;s &laquo; les Prestations &raquo;.</p>
<h1>II.      Acceptation des CGPV</h1>
<p>L&rsquo;acceptation par le Client du devis d&eacute;crivant les Prestations propos&eacute;es ou l&rsquo;achat du Produit par le Client vaut prise de connaissance et acceptation des CGPV qui lui seront remises et qui seront &eacute;galement disponibles sur le site https://save.co/apropos/cgv/ et affich&eacute;es en magasin.</p>
<p>Il ne pourra &ecirc;tre d&eacute;rog&eacute; aux CGSV que par accord &eacute;crit conclu entre le Client et SAVE.</p>
<h1>III.      Dispositions contractuelles sp&eacute;cifiques applicables aux Prestations</h1>
<h2>A. Modalit&eacute;s de r&eacute;alisation des Prestations</h2>
<h3>1.   Accessibilit&eacute;</h3>
<p>Les Prestations sont accessibles &agrave; tous les Clients de SAVE.</p>
<h2>B. Renonciation aux dispositions des CGPV</h2>
<p>Le fait que SAVE ou le Client ne se pr&eacute;vale pas &agrave; un moment donn&eacute; de l'une des stipulations des CGPV ne peut &ecirc;tre interpr&eacute;t&eacute; comme valant renonciation, de sa part, &agrave; se pr&eacute;valoir ult&eacute;rieurement de l'une quelconque desdites stipulations.</p>
<h3>&nbsp;</h3>
<h3>1.   Liste des Prestations</h3>
<p><strong><em>La liste des Prestations est indicative et non exhaustive.</em></strong></p>

<p>SAVE propose, au choix du Client, une offre de Service &agrave; la demande de r&eacute;paration (SAU) ou un Service Apr&egrave;s Ventes (SAV) d'assistance, dont voici une liste non exhaustive de Prestations :</p>
<ul>
<li>Diagnostic personnalis&eacute; de l&rsquo;Appareil,</li>
<li>Param&eacute;trage de l&rsquo;Appareil,</li>
<li>Remise &agrave; niveau du logiciel de l&rsquo;Appareil,</li>
<li>R&eacute;paration de l&rsquo;Appareil en cas de panne, casse ou oxydation,</li>
<li>Changement de pi&egrave;ces et d'accessoires de l&rsquo;Appareil,</li>
<li>Pr&ecirc;t d'un appareil en cas d'immobilisation de l&rsquo;Appareil, sous r&eacute;serve de remplir les conditions de pr&ecirc;t d&eacute;finies en magasin (dur&eacute;e de pr&ecirc;t limit&eacute;e et</li>
</ul>

<p>remise d&rsquo;une remise),</p>
<ul>
<li>R&eacute;novation en cas d'usure ou de d&eacute;t&eacute;rioration accidentelle,</li>
<li>Offres d'accessoires pour une utilisation optimale,</li>
<li></li>
</ul>
<p>Si le Client demande &agrave; SAVE une prestation de service qui ne figure pas dans la liste, le magasin SAVE peut accepter de rendre cette prestation de service sp&eacute;cifique, &agrave; la condition de :</p>
<ul>
<li>R&eacute;aliser un devis d&eacute;taill&eacute; et le transmettre au Client ;</li>
<li>D&rsquo;obtenir un accord &eacute;crit du Client.</li>
</ul>
<h3>2.   Choix des Prestations</h3>
<p>Les Prestations peuvent &ecirc;tre souscrites par le Client s&eacute;par&eacute;ment et &ecirc;tre compl&eacute;t&eacute;es et/ou modifi&eacute;es selon les Appareils, les demandes des partenaires SAVE et les imp&eacute;ratifs techniques, de d&eacute;lai ou de disponibilit&eacute; des Appareils, notamment des pi&egrave;ces d&eacute;tach&eacute;es.</p>

<p>Chacune des Prestations disponibles sera pr&eacute;conis&eacute;e par SAVE au Client &agrave; l'issue d'un diagnostic personnalis&eacute; et fera l'objet, &agrave; la demande du Client, d'une information compl&eacute;mentaire sur l'intervention propos&eacute;e.</p>

<p>Apr&egrave;s acceptation par le Client des Prestations propos&eacute;es par Save, ce dernier proposera un devis tenant compte des Prestations souhait&eacute;es. Le Client est libre de refuser ou d&rsquo;accepter le devis.</p>

<p>Une fois le devis accept&eacute;, SAVE r&eacute;alisera les Prestations dans le respect des d&eacute;lais mentionn&eacute;s &agrave; l&rsquo;article 5 des CGPV ci-apr&egrave;s.</p>

<h3>3.   R&eacute;alisation des Prestations</h3>
<p>Les Prestations sont r&eacute;alis&eacute;es soit par un conseiller SAVE au sein d&rsquo;un magasin franchis&eacute;, soit par des CRA (Centres de r&eacute;parations agr&eacute;&eacute;s), &agrave; la suite de la signature du devis par le Client et du r&egrave;glement par ce dernier du forfait Diagnostic.</p>

<p>Toute Prestation r&eacute;alis&eacute;e sur l&rsquo;Appareil du Client peut &ecirc;tre r&eacute;alis&eacute;e en sa pr&eacute;sence, SAVE ne pouvant &ecirc;tre tenu responsable d'un quelconque dysfonctionnement des syst&egrave;mes d&rsquo;exploitation install&eacute;s sur l&rsquo;Appareil du Client.</p>
<p>Le Client s'engage &agrave; communiquer &agrave; Save les informations n&eacute;cessaires &agrave; la r&eacute;alisation des Prestations comme les identifiants, les mots de passe par exemple.</p>

<p><strong>A d&eacute;faut de communication de ces informations n&eacute;cessaires, le Client reconnait que SAVE ne sera pas en mesure de r&eacute;aliser les Prestations.</strong></p>

<p>En fonction de la dur&eacute;e pr&eacute;visionnelle de l'intervention, de la disponibilit&eacute; des conseillers et techniciens, selon les cas, une intervention imm&eacute;diate ou une prise de rendez-vous ou une date de retour du CRA pourra &ecirc;tre propos&eacute;e au Client.</p>

<p>A titre indicatif, la complexit&eacute; ou la dur&eacute;e des Prestations varie de 40 minutes sur place &agrave; 35 jours ouvr&eacute;s pour les r&eacute;parations en CRA selon les conditions contractuelles de service apr&egrave;s-vente dont rel&egrave;ve l&rsquo;Appareil.</p>

<p>En cas d'impossibilit&eacute; de finaliser la Prestation pour des probl&egrave;mes techniques ou d&rsquo;impossibilit&eacute; de r&eacute;paration (par exemple pour les Appareils hors services, oxyd&eacute;s ou en raison de pi&egrave;ces indisponibles), le Diagnostic personnalis&eacute; restera n&eacute;anmoins d&ucirc; par le Client.</p>

<p>Les Prestations d&eacute;crites seront factur&eacute;es selon la fiche tarifaire en vigueur (forfait) mise &agrave; disposition dans le magasin SAVE ou le devis personnalis&eacute; sign&eacute; par le Client.</p>

<h2>C. Conditions financi&egrave;res applicables aux Prestations</h2>
<p>Les prix applicables s&rsquo;entendent toutes taxes comprises sur la base des tarifs communiqu&eacute;s au Client au moment de la signature du devis. Les Prestations et Produits (accessoires) sont assujettis &agrave; la TVA en vigueur &agrave; la date de r&eacute;alisation des Prestations qui figurera sur la facture.</p>

<p>Les pr&eacute;sentes conditions financi&egrave;res s&rsquo;appliquent &eacute;galement aux prestations de service non list&eacute;es, demand&eacute;es express&eacute;ment par le Client &agrave; SAVE.</p>

<h2>D. Garantie commerciale SAVE STORE</h2>
<p>SAVE offre une garantie commerciale, au sens de l&rsquo;article L.217-21 du Code de la consommation, d&rsquo;un (1) an qui a pour objet de couvrir la Prestation de r&eacute;paration r&eacute;alis&eacute;e sur l&rsquo;Appareil du Client. Cette garantie commerciale est r&eacute;duite &agrave; trois (3) mois en cas d&rsquo;oxydation de l&rsquo;Appareil.</p>
<p>La dur&eacute;e de 1 an ou de 3 mois de garantie court &agrave; compter de la Prestation de r&eacute;paration r&eacute;alis&eacute;e par SAVE.</p>
<p>Pour b&eacute;n&eacute;ficier de la garantie commerciale, le Client devra pr&eacute;senter la facture de r&eacute;paration attestant de la Prestation r&eacute;alis&eacute;e par Save sur ledit l&rsquo;Appareil.</p>
<h1>IV.      Dispositions Contractuelles sp&eacute;cifiques applicables &agrave; la vente d&rsquo;un Produit</h1>
<p>Le Client doit avoir la capacit&eacute; juridique de contracter.</p>
<p>Le Client reconnait avoir pris connaissance des CGPV pr&eacute;alablement &agrave; tout achat et d&eacute;clare les accepter dans leur int&eacute;gralit&eacute;.</p>
<p>Les CGPV sont librement consultables par le Client dans le Magasin. Par ailleurs, elles sont &eacute;galement communiqu&eacute;es sans d&eacute;lai &agrave; tout Client qui en fait la demande en Magasin, en version papier et/ou &eacute;lectronique selon son choix.</p>
<h2>A. Champ d&rsquo;application</h2>
<p>Les CGPV r&eacute;gissent les ventes des Produits vis&eacute;s &agrave; l&rsquo;article I., par SAVE aux Clients consommateurs qui souhaitent effectuer un achat direct au sein d&rsquo;un Magasin. Les CGPV s&rsquo;appliquent &agrave; toute vente ou fourniture de Produits neufs et/ou Produits d&rsquo;occasion et/ou de Produits reconditionn&eacute;s r&eacute;alis&eacute;e par SAVE au profit d&rsquo;un Client en Magasin.</p>

<p>Tout achat sera r&eacute;gi par les CGPV en vigueur au moment de la vente.</p>

<h2>B. Prix et conditions de r&egrave;glement&nbsp;</h2>
<ul>
<li>Le paiement par le Client de l&rsquo;achat des Produits s&rsquo;effectue par :
<ol>
<li>carte bancaire (Visa, MasterCard, autres cartes</li>
</ol>
</li>
</ul>
<p>bleues ou cartes Aurore en fonction des points de vente),</p>
<ol start="2">
<li>ch&egrave;que (sur pr&eacute;sentation d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute;),</li>
<li>ou esp&egrave;ces dans la limite de mille (1 000) euros TTC.</li>
</ol>
<p>Le Client peut compl&eacute;ter le paiement relatif &agrave; l&rsquo;achat d&rsquo;un Produit &agrave; l&rsquo;aide d&rsquo;un avoir ou d&rsquo;une carte cadeau accept&eacute;e dans les Magasins.</p>
<h2>&nbsp;</h2>
<h2>C. Formation du contrat de vente&nbsp;</h2>
<ul>
<li>Le Produit demeure la propri&eacute;t&eacute; de SAVE jusqu&rsquo;au complet paiement du prix par le Client.</li>
</ul>

<p>Le lieu de d&eacute;livrance du Produit s&rsquo;entend des Magasins. Les contenus num&eacute;riques et services num&eacute;riques sont fournis par tout moyen appropri&eacute; pour y acc&eacute;der ou le t&eacute;l&eacute;charger en tout lieu physique ou virtuel propos&eacute; par SAVE et choisi par le Client.</p>

<h2>D. Remboursement du Produit</h2>
<p>Le Client peut demander aupr&egrave;s de SAVE un remboursement du Produit achet&eacute; depuis moins de 15 jours calendaires, dans les conditions et limites d&eacute;taill&eacute;es ci-apr&egrave;s.</p>

<h3>1.   Dispositions g&eacute;n&eacute;rales</h3>
<p>Lorsque le remboursement du Produit (hors biens d&eacute;mat&eacute;rialis&eacute;s) est effectu&eacute; sous forme d&rsquo;un avoir, le Client peut demander exclusivement le remboursement de cet avoir aupr&egrave;s du Magasin dans lequel il a effectu&eacute; son achat, qui se r&eacute;serve n&eacute;anmoins le droit de refuser lorsque le Produit a &eacute;t&eacute; achet&eacute; il y a plus de 15 jours.</p>

<p>Les avoirs &eacute;mis sont d&rsquo;une dur&eacute;e de validit&eacute; de trois</p>
<p>(3) mois &agrave; compter de leur date d&rsquo;&eacute;mission. Au-del&agrave; de cette date, aucun remboursement de sera effectu&eacute;.</p>

<p>Le remboursement total d&rsquo;un achat ne sera pas accept&eacute;, si dans le cadre de la vente d&rsquo;origine il y a eu le rachat d&rsquo;un Produit d&rsquo;occasion et/ou une utilisation d&rsquo;un avoir (papier ou &eacute;lectronique), d&rsquo;un ch&egrave;que cadeau ou d&rsquo;une carte cadeau.</p>
<p>Le Client ne pourra pas obtenir de remboursement de la diff&eacute;rence de prix s&rsquo;il trouve son Produit moins cher dans une enseigne concurrente. SAVE ne pratique pas la politique du &laquo; satisfait ou rembours&eacute; &raquo;.</p>

<h3>2.   Produit achet&eacute; neuf</h3>
<h4>a)   Produit neuf non ouvert (dans son emballage d&rsquo;origine)</h4>
<p>SAVE pourra, &agrave; la demande du Client :</p>
<ul>
<li>Proc&eacute;der &agrave; son remboursement, sous r&eacute;serve que les conditions suivantes soient remplies :
<ul>
<li>Produit dans son emballage d&rsquo;origine ;</li>
<li>Achat du Produit r&eacute;gl&eacute; en esp&egrave;ces ou par carte bancaire ;</li>
<li>Production par le Client d&rsquo;une preuve d&rsquo;achat ;</li>
<li>Demande de remboursement pr&eacute;sent&eacute;e le jour m&ecirc;me de l&rsquo;achat, la date mentionn&eacute;e sur la preuve d&rsquo;achat faisant foi.</li>
</ul>
</li>
<li>Ou remettre au Client un avoir correspondant au montant d&rsquo;achat du Produit,</li>
</ul>
<p>sous r&eacute;serve de la production d&rsquo;une preuve d&rsquo;achat.</p>

<h4>b)   Produit neuf ouvert</h4>
<p>Un Produit est consid&eacute;r&eacute; comme &laquo; ouvert &raquo; :</p>
<ul>
<li>si son emballage d&rsquo;origine a subi une alt&eacute;ration ou,</li>
<li>lorsqu&rsquo;il est pr&eacute;sent&eacute; sans son emballage d&rsquo;origine.</li>
</ul>
<p>Tout Produit ouvert ne pourra pas &ecirc;tre repris &agrave; son prix d'origine (version neuve). Cet avoir est valable uniquement au sein des Magasins.</p>

<p>Toute demande de reprise d&rsquo;un Produit ouvert doit &ecirc;tre faite dans un d&eacute;lai maximal de trente (30) jours calendaires &agrave; compter de la date d&rsquo;achat, la date mentionn&eacute;e sur la preuve d&rsquo;achat faisant foi.</p>

<h3>3.   Produit achet&eacute; d&rsquo;occasion, y compris reconditionn&eacute;</h3>
<h4>a)   Produit achet&eacute; d&rsquo;occasion rapport&eacute; le jour m&ecirc;me de son achat</h4>
<p>SAVE pourra, &agrave; la demande du Client :</p>
<ul>
<li>Proc&eacute;der &agrave; son remboursement, sous r&eacute;serve que les conditions suivantes soient remplies :
<ul>
<li>Produit dans son emballage d&rsquo;origine ;</li>
<li>Produit r&eacute;gl&eacute; en esp&egrave;ces ou par carte bancaire ;</li>
<li>Production par le Client d&rsquo;une preuve d&rsquo;achat ;</li>
<li>Demande de remboursement pr&eacute;sent&eacute;e le jour m&ecirc;me de l&rsquo;achat, la date</li>
</ul>
</li>
</ul>
<p>mentionn&eacute;e sur la preuve d&rsquo;achat faisant foi.</p>
<ul>
<li>remettre au Client un avoir correspondant au montant d&rsquo;achat du Produit,</li>
</ul>
<p>sous r&eacute;serve de la production d&rsquo;une preuve d&rsquo;achat.</p>

<h4>b)   Produit achet&eacute; d&rsquo;occasion rapport&eacute; un jour apr&egrave;s la date d&rsquo;achat et dans un d&eacute;lai de trente (30) jours calendaires &agrave; compter de cette date</h4>
<p>Au cours de cette p&eacute;riode, SAVE remettra au Client au Client un avoir correspondant au montant d&rsquo;achat du Produit, sous r&eacute;serve de la production d&rsquo;une preuve d&rsquo;achat.</p>

<h2>E. &Eacute;change de produits</h2>
<p>Les Produits (hors biens d&eacute;mat&eacute;rialis&eacute;s) peuvent faire l&rsquo;objet d&rsquo;un &eacute;change en</p>
<p>Magasin, sous r&eacute;serve du respect des conditions suivantes :</p>
<ul>
<li>&Eacute;change dans un d&eacute;lai maximal de trente (30) jours calendaires &agrave; compter de la date d&rsquo;achat (preuve d&rsquo;achat faisant foi) ;</li>
<li>Production par le Client d&rsquo;une preuve d&rsquo;achat</li>
</ul>
<p>;</p>
<ul>
<li>Remise du Produit dans son emballage d&rsquo;origine, dans un &eacute;tat identique &agrave;</li>
</ul>
<p>celui dans lequel il a &eacute;t&eacute; vendu ;</p>
<ul>
<li>&Eacute;change dans la limite des stocks disponibles.</li>
</ul>
<p>La possibilit&eacute; pour les Clients d&rsquo;&eacute;changer des Produits en Magasin dans les conditions d&eacute;finies ci- avant rel&egrave;ve de la politique commerciale de SAVE et s&rsquo;ajoute aux garanties l&eacute;gales dont b&eacute;n&eacute;ficient les Clients et rappel&eacute;es ci-apr&egrave;s.</p>

<h2>F. Garanties</h2>
<h3>1.   Garanties l&eacute;gales</h3>
<p>Les Produits vendus par SAVE b&eacute;n&eacute;ficient des garanties suivantes :</p>
<ul>
<li>La garantie l&eacute;gale de conformit&eacute; (articles L.217-3 &agrave; L.217-20 du Code de la consommation) (cf. a))</li>
</ul>

<ul>
<li>La garantie l&eacute;gale des vices cach&eacute;s (articles 1641 et suivants du Code civil) (cf. b))</li>
<li>D&rsquo;une &eacute;ventuelle garantie commerciale (articles L.217-21 &agrave; L.217-24 du Code de la consommation), telle qu&rsquo;elle figure sur la facture d&rsquo;achat du Produit.</li>
</ul>

<h4>a)   Garantie l&eacute;gale de conformit&eacute;</h4>
<ul>
<li>Pour les Produits achet&eacute;s avant jusqu&rsquo;au 31 d&eacute;cembre</li>
</ul>
<p>2021</p>
<p>Conform&eacute;ment aux obligations l&eacute;gales incombant &agrave; SAVE en sa qualit&eacute; de vendeur professionnel, elle r&eacute;pond des d&eacute;fauts de conformit&eacute; qui apparaissent sur le Produit pendant une dur&eacute;e de 2 ans &agrave; compter de sa d&eacute;livrance. Il est rappel&eacute; que le Client :</p>
<ul>
<li>B&eacute;n&eacute;ficie d&rsquo;un d&eacute;lai de deux (2) ans &agrave; compter de la d&eacute;livrance du Produit pour agir contre SAVE,</li>
<li>Peut choisir, gratuitement entre la r&eacute;paration et le remplacement du Produit, sous r&eacute;serve des conditions de co&ucirc;ts pr&eacute;vues par l&rsquo;article L.217-9 du Code de consommation. A d&eacute;faut, si la r&eacute;paration ou l&rsquo;&eacute;change sont impossibles ou n&rsquo;ont pu &ecirc;tre mis en &oelig;uvre pendant le mois qui suit la prise en charge du Produit, SAVE remboursera au Client le prix du Produit,</li>
<li>Est dispens&eacute; de rapporter la preuve de l&rsquo;existence du d&eacute;faut de conformit&eacute; du Produit dans la dur&eacute;e de la garantie l&eacute;gale de conformit&eacute;, soit deux (2) ans &agrave; compter de la d&eacute;livrance du Produit s&rsquo;il s&rsquo;agit d&rsquo;un Produit neuf.</li>
</ul>

<p>La garantie l&eacute;gale de conformit&eacute; s&rsquo;applique ind&eacute;pendamment de la garantie commerciale qui peut &eacute;ventuellement couvrir le Produit et qui serait propos&eacute; par ailleurs par SAVE dans le devis sign&eacute; par le Client.</p>

<p>Pour les Produits achet&eacute;s d&rsquo;occasion ou reconditionn&eacute;s et au-del&agrave; de six (6) mois &agrave; compter de la d&eacute;livrance du Produit, le Client devra prouver que le d&eacute;faut de conformit&eacute; qu&rsquo;il a d&eacute;couvert, existait au moment de la d&eacute;livrance du Produit, pour faire jouer la garantie l&eacute;gale de conformit&eacute;.</p>

<p>Dans le cas o&ugrave; le Produit est &eacute;ligible &agrave; la garantie l&eacute;gale de conformit&eacute;, le Client devra retourner le Produit &agrave; SAVE dans l&rsquo;&eacute;tat dans lequel il l&rsquo;a re&ccedil;u avec l&rsquo;ensemble des &eacute;l&eacute;ments (accessoires, notice, etc.), ainsi qu&rsquo;une copie de la facture d&rsquo;achat. Le Produit retourn&eacute; par voie postale doit l&rsquo;&ecirc;tre dans un emballage permettant un transport dans de bonnes conditions. Dans ce cas les frais de renvoi du Produit seront rembours&eacute;s au Client sur la base du tarif factur&eacute; et sur pr&eacute;sentation des justificatifs.</p>

<ul>
<li>Pour les Produits achet&eacute;s &agrave; compter du 1erjanvier 2022&nbsp;Conform&eacute;ment aux obligations l&eacute;gales incombant &agrave; SAVE, en sa qualit&eacute; de vendeur professionnel elle r&eacute;pond des d&eacute;fauts de conformit&eacute; qui apparaissent sur le Produit pendant une dur&eacute;e de 2 ans &agrave; compter de sa d&eacute;livrance.</li>
</ul>
<p>Lorsque le Produit comporte des &eacute;l&eacute;ments num&eacute;riques fournis en continu pendant une p&eacute;riode sup&eacute;rieure &agrave; deux (2) ans, SAVE r&eacute;pond des d&eacute;fauts de conformit&eacute; qui apparaissent sur ces &eacute;l&eacute;ments num&eacute;riques pendant la dur&eacute;e pr&eacute;vue au contrat.</p>

<p>Il est rappel&eacute; que le Client :</p>
<ul>
<li>B&eacute;n&eacute;ficie d&rsquo;un d&eacute;lai de deux (2) ans &agrave; compter de la d&eacute;livrance du Produit pour agir contre SAVE,</li>
<li>Peut choisir, gratuitement entre la r&eacute;paration et le remplacement du Produit, sous r&eacute;serve des conditions de co&ucirc;ts pr&eacute;vues par l&rsquo;article L.217-12 du Code de consommation. A d&eacute;faut, si la r&eacute;paration ou l&rsquo;&eacute;change sont impossibles ou n&rsquo;ont pu &ecirc;tre mis en &oelig;uvre pendant le mois qui suit la prise en charge du Produit, SAVE remboursera au Client le prix du Produit,</li>
<li>Est dispens&eacute; de rapporter la preuve de l&rsquo;existence du d&eacute;faut de conformit&eacute; du Produit dans la dur&eacute;e de la garantie l&eacute;gale de conformit&eacute;, soit deux (2) ans &agrave; compter de la d&eacute;livrance du Produit s&rsquo;il s&rsquo;agit d&rsquo;un Produit neuf.</li>
</ul>

<p>La garantie l&eacute;gale de conformit&eacute; s&rsquo;applique ind&eacute;pendamment de la garantie commerciale qui peut &eacute;ventuellement couvrir le Produit et qui serait propos&eacute; par ailleurs par SAVE dans le devis sign&eacute; par le Client.</p>

<p>Pour les Produits achet&eacute;s d&rsquo;occasion ou reconditionn&eacute;s et au-del&agrave; de 12 mois &agrave; compter de la d&eacute;livrance du Produit, le Client devra prouver que le d&eacute;faut de conformit&eacute; qui a d&eacute;couvert, existait au moment de la d&eacute;livrance du Produit, pour faire jouer la garantie l&eacute;gale de conformit&eacute;.</p>

<p>La r&eacute;paration du Produit dans le cadre de la garantie l&eacute;gale de conformit&eacute; entrainera une prolongation de la dur&eacute;e de la garantie de 6 (six) mois.</p>
<p>Lorsque le Produit est &eacute;chang&eacute; dans le cadre de la garantie l&eacute;gale de conformit&eacute; en raison de l&rsquo;impossibilit&eacute; pour SAVE de pouvoir r&eacute;parer le Produit dans le d&eacute;lai d&rsquo;un mois qui suit la demande du Client, le Produit de remplacement est couvert par une nouvelle garantie l&eacute;gale de conformit&eacute; de deux (2) ans &agrave; compter de sa remise au Client. En revanche, lorsque le Produit est remplac&eacute; &agrave; la demande du Client, le Produit de remplacement sera couvert au titre de la garantie l&eacute;gale de conformit&eacute; du premier Produit achet&eacute; pour la dur&eacute;e restant &agrave; courir.</p>

<p>Dans ce cas, le Client devra retourner le Produit &agrave; SAVE dans l&rsquo;&eacute;tat dans lequel il les a re&ccedil;us avec l&rsquo;ensemble des &eacute;l&eacute;ments (accessoires, notice, etc.), ainsi qu&rsquo;une copie de la facture d&rsquo;achat. Les Produits retourn&eacute;s par voie postale doivent l&rsquo;&ecirc;tre dans un emballage permettant un transport dans de bonnes conditions. Dans ce cas les frais de renvoi du Produit seront rembours&eacute;s au Client sur la base du tarif factur&eacute; et sur pr&eacute;sentation des justificatifs.</p>

<h4>b)   Garantie l&eacute;gale contre les vices cach&eacute;s</h4>
<p>Il est rappel&eacute; que le Client peut d&eacute;cider de mettre en &oelig;uvre la garantie contre les d&eacute;fauts cach&eacute;s de la chose vendue au sens de l&rsquo;article 1641 du Code civil et que dans cette hypoth&egrave;se, il peut choisir entre la r&eacute;solution de la vente (annulation) ou une r&eacute;duction du prix de vente conform&eacute;ment &agrave; l&rsquo;article 1644 du Code civil.</p>

<p>Cette garantie pourra &ecirc;tre mise en &oelig;uvre dans un d&eacute;lai de deux ans &agrave; compter de la d&eacute;couverte du vice. Il reviendra au Client alors de prouver notamment que le d&eacute;faut existait au moment de l&rsquo;achat, qu&rsquo;il &eacute;tait cach&eacute; et qu&rsquo;il rend le Produit inutilisable.</p>

<h1>V.      R&eacute;clamation / M&eacute;diation / Litiges / Droit applicable</h1>
<p><a href="mailto:serviceclient@save.co">Pour toute r&eacute;clamation en lien avec les Services ou les Produits, le Client peut contacter le service client&egrave;le par mail &agrave; l&rsquo;adresse &eacute;lectronique suivante :&nbsp;</a><a href="mailto:serviceclient@save.co">serviceclient@save.co.</a></p>

<p><a href="https://cm2c.net/">Save et le Client s'efforceront de r&eacute;gler &agrave; l'amiable tout litige concernant l'ex&eacute;cution des Prestations. &Agrave; d&eacute;faut de r&egrave;glement amiable, la partie la plus diligente saisira la juridiction comp&eacute;tente. Le Client a la possibilit&eacute; de saisir, si un d&eacute;saccord subsiste, un m&eacute;diateur inscrit sur la liste des m&eacute;diateurs &eacute;tablie par la Commission d&rsquo;&Eacute;valuation et de Contr&ocirc;le de la M&eacute;diation de la Consommation (CECMC) accessible via son site internet :&nbsp;</a><u>https://cm2c.net/</u>&nbsp;<a href="mailto:cm2c@cm2c.net">ou par courriel :&nbsp;</a><u>cm2c@cm2c.net</u>&nbsp;ou par courrier postal &agrave; l&rsquo;adresse suivante : 14, rue saint Jean 75017 Paris.</p>

<p>Le droit fran&ccedil;ais est applicable. La langue du pr&eacute;sent contrat est le fran&ccedil;ais.</p>

<h1>VI.      Responsabilit&eacute;</h1>
<p>SAVE met en &oelig;uvre tous les moyens n&eacute;cessaires &agrave; la bonne ex&eacute;cution des</p>
<p>Prestations et n&rsquo;est engag&eacute;e que par une responsabilit&eacute; de moyens.</p>

<p>La responsabilit&eacute; de SAVE ne peut &ecirc;tre engag&eacute;e en cas d'erreur de manipulation par le Client, de d&eacute;lai d&eacute;pass&eacute; ou d'utilisation non-conforme de l&rsquo;Appareil</p>
<p>/ du Produit par le Client. En particulier, la responsabilit&eacute; de SAVE ne pourra &ecirc;tre recherch&eacute;e si les indications pr&eacute;vues dans la documentation du Constructeur et/ou Op&eacute;rateur ne sont pas respect&eacute;es par le Client.</p>

<p>Le Client reconna&icirc;t &ecirc;tre inform&eacute; et accepter que la sauvegarde par ses soins de ses donn&eacute;es personnelles contenues dans l&rsquo;Appareil / le Produit et la suppression desdites donn&eacute;es dans l&rsquo;Appareil / le Produit est pr&eacute;alablement n&eacute;cessaire &agrave; l'intervention de SAVE si l&rsquo;Appareil / le Produit le permet. Si le support ne le permet pas ou si le Client refuse d'effectuer une telle sauvegarde des donn&eacute;es et &agrave; une destruction des donn&eacute;es dans l&rsquo;Appareil / le Produit, le Client reconna&icirc;t avoir &eacute;t&eacute; inform&eacute; que l'intervention de SAVE peut entra&icirc;ner la perte de tout ou partie de ses donn&eacute;es et en accepte les cons&eacute;quences.</p>

<p>De ce fait, Save ne pourra &ecirc;tre tenu pour responsable en cas de perte ou de d&eacute;gradation de tout ou partie de ces donn&eacute;es personnelles r&eacute;sultant de l&rsquo;ex&eacute;cution des Prestations.</p>

<h1>VII.      Force majeure</h1>
<p>En ce qui concerne le cas de force majeure, la responsabilit&eacute; de Save ne saurait &ecirc;tre recherch&eacute;e en cas de force majeure dans sa d&eacute;finition r&eacute;sultant de l'article 1218 du code civil habituellement retenue par la juridiction de la Cour de cassation.</p>

<h1>VIII.      Donn&eacute;es personnelles</h1>
<p>Sans pr&eacute;juger de l&rsquo;application des dispositions figurant &agrave; l&rsquo;article l&laquo; Responsabilit&eacute; &raquo; en ce qui concerne les donn&eacute;es personnelles contenues dans les Appareils / les Produits et qui doivent &ecirc;tre sauvegard&eacute;es par le Client pr&eacute;alablement &agrave; l&rsquo;ex&eacute;cution des Prestations et d&eacute;truites dans les Appareils / les Produits avant leur remise &agrave; SAVE, le Client est express&eacute;ment inform&eacute; de l'existence et d&eacute;clare accepter le traitement automatis&eacute; de ses donn&eacute;es personnelles recueillies aupr&egrave;s de lui par SAVE (et ses sous-traitants) dans le cadre des Prestations r&eacute;alis&eacute;es.</p>

<p>Il lui est express&eacute;ment rappel&eacute; que, conform&eacute;ment aux dispositions l&eacute;gales et r&eacute;glementaires en vigueur relatives au traitement des donn&eacute;es personnelles, la fourniture de ces informations est obligatoire car n&eacute;cessaire &agrave; la r&eacute;alisation des Prestations, et qu&rsquo;en cons&eacute;quence, ces informations pourront &ecirc;tre conserv&eacute;es jusqu&rsquo;au terme de la prescription des actions juridiques pouvant en d&eacute;couler.</p>

<p>Ces informations sont destin&eacute;es exclusivement &agrave; SAVE (et ses sous-traitants) pour les besoins de r&eacute;alisation des Prestations, &agrave; ses partenaires contractuels concourant &agrave; la r&eacute;alisation de celles-ci ainsi, le cas &eacute;ch&eacute;ant, qu'aux autorit&eacute;s administratives et judiciaires concern&eacute;es.</p>

<p>Le Client dispose d'un droit d'acc&egrave;s, d&rsquo;opposition pour motif l&eacute;gitime, de rectification et de portabilit&eacute; sur les donn&eacute;es personnelles le concernant figurant dans les fichiers de SAVE. Il dispose &eacute;galement d&rsquo;un droit de</p>

<p>suppression sur les donn&eacute;es personnelles le concernant qui sont inexactes, incompl&egrave;tes, &eacute;quivoques, p&eacute;rim&eacute;es, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite. Ces droits s&rsquo;exercent selon les modalit&eacute;s d&eacute;finies par les dispositions l&eacute;gales et r&eacute;glementaires en vigueur relatives au traitement des donn&eacute;es personnelles, en contactant SAVE.</p>

<p><a href="mailto:rgpd@save.co">Ces droits s&rsquo;exercent par l&rsquo;envoi d&rsquo;un courrier recommand&eacute; au si&egrave;ge social ci-dessous ou par l&rsquo;envoi d&rsquo;un mail &agrave; l&rsquo;adresse&nbsp;</a>rgpd@save.co . Les demandes sont trait&eacute;es dans un d&eacute;lai maximum d'un mois.</p>

<p>Le Client peut &eacute;galement transmettre (et modifier &agrave; tout moment) des directives concernant la conservation, l&rsquo;effacement et la communication de ses donn&eacute;es &agrave; caract&egrave;re personnel apr&egrave;s son d&eacute;c&egrave;s et le cas &eacute;ch&eacute;ant d&eacute;signer une personne afin de les mettre en &oelig;uvre. En l&rsquo;absence de directives et/ou de d&eacute;signation, les dispositions l&eacute;gales et r&eacute;glementaires en vigueur relatives au traitement des donn&eacute;es personnelles s&rsquo;appliqueront.</p>

<p>Toute d&eacute;claration fausse ou irr&eacute;guli&egrave;re peut faire l'objet d'un traitement sp&eacute;cifique destin&eacute; &agrave; pr&eacute;venir ou identifier une fraude.</p>

    `,
    },
    {
        url: termsLinks.mentions_legales,
        label: 'Mentions légales',
        title: 'Retrouvez les mentions légales de Save',
        meta_desc: 'Mentions légales de la société Save',
        content: `
      <h3 class="subtitle">Le site www.save.co est édité par</h3>
      <p>
        SAVE STORE, propriétaire de l'enseigne SAVE <br />SAS au capital de
        1.440.000 EUR <br />Siège social : 8 avenue de l'arche - 92400 COURBEVOIE
        <br />Téléphone : 01 45 74 75 00 <br />Mail : info@save.co <br />SIRET: 490
        218 161 <br />APE : 9512Z <br />TVA : FR 53 791 070 907
      </p>
      <p>
        Le directeur de la publication est Philippe Duchêne. 
      </p>
      <h3 class="subtitle">Le site www.save.co est hébergé par</h3>
      <p>
        Amazon Web Services EMEA SARL 
        <br />SARL au capital de 25 000 € RCS Luxembourg 831 001 334 00018 
        <br />Code APE 62.03Z – N° TVA : FR30831001334 
        <br />Siège social : 38 AV JOHN F KENNEDY L 1855, LUXEMBOURG LUXEMBOURG.
      </p>
      <h3 class="subtitle">Liens hypertexte</h3>
      <p>
        Les présentes SAVE ne contrôle pas les sites en connexion avec le sien, et ne
        saurait donc être responsable de leur contenu. Les risques liés à
        l’utilisation de ces sites incombent pleinement à l’utilisateur. Il se
        conformera à leurs conditions d’utilisation. générales entrent en vigueur à
        compter de son acceptation par le Client sur le Site, et restent en vigueur
        jusqu’à extinction des droits et obligations à la charge de l’une ou l’autre
        des parties.
      </p>
      <h3 class="subtitle">Droits d‘auteur et propriété intellectuelle</h3>
      <p>
        La conception générale, ainsi que les textes, images animées ou non, sons,
        savoir faire et tout autres éléments composants le site sont la propriété
        exclusive de SAVE et/ou de ses fournisseurs.
      </p>
      <h3 class="subtitle">Contrefaçon</h3>
      <p>
        Toute reproduction ou représentation de ce service (contenus, pages, scripts,
        etc.), en tout ou partie, sur un quelconque support, présent ou futur, est
        strictement interdite. Le non-respect de cette interdiction constitue une
        contrefaçon pouvant engager la responsabilité civile et pénale du
        contrefacteur. Les informations contenues dans ce service sont non
        contractuelles et sujettes à modification sans préavis.
      </p>
    `,
    },
    {
        url: termsLinks.cookies,
        label: 'Cookies',
        title: 'Retrouvez la politique Cookies de Save',
        meta_desc: 'Retrouvez la politique Cookies de Save',
        content: `
      <p>
        SAVE STORE, propriétaire de l'enseigne SAVE SAS au capital de
        1.440.000 EUR Siège social : 8 avenue de l'arche - 92400 COURBEVOIE
        Téléphone : 01 45 74 75 00 Mail : info@save.co SIRET: 490 218 161 APE :
        9512Z TVA : FR 53 791 070 907 Le directeur de la publication est Philippe Duchêne
      </p>
      <h3>COOKIES SUR LE SITE DE SAVE STORE</h3>
      <h4>ARTICLE 1. DONNÉES PERSONNELLES QUE NOUS RECUEILLONS</h4>
      <p>
        Vous pouvez être invités sur le Site à fournir des données personnelles qui
        vous concernent ou qui sont relatives à un tiers. Cette collecte de données
        s'effectue notamment :
      </p>
      <ul>
        <li>lorsque vous validez vos informations personnelles, avant Commande</li>
        <li>lorsque vous effectuez une Commande sur le Site,</li>
        <li>lorsque vous contactez le Service Client,</li>
        <li>Lorsque vous créez un compte,</li>
        <li>lorsque vous naviguez sur le Site,</li>
        <li>
          lorsque vous partagez une page ou une offre avec un ami par envoi d'un
          e-mail,
        </li>
        <li>
          lorsque vous partagez une page ou une offre par l'intermédiaire d'un réseau
          social.
        </li>
      </ul>
      <p>
        Les données collectées sont destinées à l'usage de la société SAVE STORE,
        afin de gérer la relation commerciale avec vous et vous tenir
        informés de ses offres et actualités. Par l'intermédiaire de la société
        SAVE STORE, et si vous y avez expressément consenti en validant les
        conditions générales de ventes et d'utilisation, vous pouvez donc être amené à
        recevoir des prospections électroniques émanant de SAVE STORE
        uniquement.
        <br />
        <br />En passant Commande sur le Site, ou après avoir fourni sur le Site vos
        données personnelles, vous pouvez également être informé des offres de la
        société SAVE STORE, sous forme de newsletters, emails événementiels
        ou emails d'offres personnalisées de produits et services fournis par la
        société SAVE STORE et ses filiales. Si vous ne souhaitez plus
        recevoir ces propositions, vous pouvez nous en informer à tout moment par
        courrier ou par e-mail (coordonnées indiquées dans la rubrique « Contact » du
        Site) ou via le lien de désabonnement contenu dans chacun des emails
        commerciaux que nous vous envoyons ou dans votre compte client.
        <br />
        <br />La société SAVE STORE est responsable du traitement de vos
        données. Elles peuvent être transmises aux sociétés et sous-traitants et a ses
        prestataires, situés dans et hors de l'Union européenne, auxquels la société
        SAVE STORE fait appel dans le cadre de l'exécution des Commandes et
        de la fourniture des services proposés via le Site ou dans les conditions
        indiquées lors de la collecte des données sur le Site.
        <br />
        <br />Les traitements de données personnelles mis en œuvre par la société
        SAVE STORE via le Site ont fait l'objet des registres et
        notifications prévus par la loi française « Informatique &amp; Libertés » no
        78-17 du 6 janvier 1978, notamment auprès de la CNIL (<a
          href="https://www.google.com/url?q=http://www.cnil.fr/&amp;sa=D&amp;ust=1464346766857000&amp;usg=AFQjCNGP8Fv9U9L0k6Vqy9NVo9W0f_bd3w"
          >www.cnil.fr</a
        >).
        <br />
        <br />Conformément à cette loi, vous disposez d'un droit d'accès, de
        modification, de rectification et de suppression portant sur les données
        personnelles vous concernant, que vous pouvez exercer en écrivant à "SAVE
        STORE, Service Client - 8 avenue de l'arche - 92400 COURBEVOIE.
        <br />
        <br />Dans le cas d'une demande d'accès à vos données personnelles, une copie
        de votre pièce d'identité recto/verso est nécessaire.
      </p>
      <h4>ARTICLE 2. DONNEES RELATIVES A LA CONSULTATION DU SITE</h4>
      <p>
        Certaines informations non personnelles peuvent être recueillies a l'occasion
        de votre navigation sur le Site, comme la version de votre navigateur (Chrome,
        Firefox, Safari, Opéra, Internet Explorer, etc.), le type de système
        d'exploitation utilisé (Linux, Windows 98, Mac Os, etc.) et l'adresse IP
        (Internet Protocol) de l'ordinateur utilisé.
        <br />
        <br />La société SAVE STORE utilise des cookies, destinés à stocker
        des informations pouvant identifier votre terminal (ordinateur, tablette,
        smartphone, etc.) durant la consultation du Site afin de faciliter
        l'expérience de navigation sur ce dernier.
        <br />
        <br />En outre, la société SAVE STORE est susceptible d'acheter des
        espaces publicitaires directement ou par l'intermédiaire de prestataires afin
        de promouvoir ses activités et ses offres sur des sites ou applications de
        tiers, au moyen de contenus publicitaires (texte, graphismes, animations,
        vidéos, etc.) diffusés par ces sites ou applications.
        <br />
        <br />Seul l'émetteur d'un cookie est susceptible de lire ou de modifier des
        informations qui y sont contenues.
        <br />
        <br />Les cookies que la société SAVE STORE émet permettent :
      </p>
      <ul>
        <li>
          d'établir des statistiques et volumes de fréquentation et d'utilisation des
          divers éléments composant le Site (rubriques et contenus visités, parcours),
          et nous permettent d'améliorer l'intérêt et l'ergonomie des services
          consultés sur le Site,
        </li>
        <li>
          d'adapter la présentation du Site aux préférences d'affichage de votre
          terminal (langue utilisée, résolution d'affichage, système d'exploitation
          utilisé, etc) lors des visites sur le Site, selon les matériels et les
          logiciels de visualisation ou de lecture que ce terminal comporte,
        </li>
        <li>
          d'adapter les contenus affichés sur votre terminal, selon la navigation de
          ce terminal et/ou en fonction des données personnelles que vous nous avez
          fournies,
        </li>
        <li>
          de mémoriser des informations relatives à un formulaire que vous avez rempli
          sur le Site (inscription ou accès au compte) ou à des produits, services ou
          informations que vous avez choisis sur le Site (service souscrit, contenu
          d'un panier de commande, etc.),
        </li>
        <li>
          de vous permettre d'accéder à des espaces réservés et personnels du Site,
          grâce aux identifiants choisis lors de l'inscription au Site,
        </li>
        <li>
          de mettre en œuvre des mesures de sécurité, par exemple lorsqu'un accès
          réservé est révoqué après un certain laps de temps.
        </li>
      </ul>
      <p>
        Si vous refusez l'enregistrement de cookies sur votre terminal, ou en cas de
        suppression de ceux qui y sont enregistrés, vous ne pourrez plus bénéficier
        d'un certain nombre de fonctionnalités qui sont néanmoins nécessaires pour
        naviguer dans certains espaces du Site, en particulier, le panier de Commande.
        Le cas échéant, la société SAVE STORE décline toute responsabilité
        pour les conséquences liées au fonctionnement dégradé des services du Site
        résultant de l'impossibilité d'enregistrer ou de consulter les cookies
        nécessaires à leur fonctionnement et que vous auriez refusés ou supprimés.
      </p>
      <h4>2.1. En cas d'utilisation partagée de votre terminal</h4>
      <p>
        Si votre terminal est utilisé par plusieurs personnes et lorsqu'un même
        terminal dispose de plusieurs logiciels de navigation, la société SAVE
        STORE ne saurait s'assurer de manière certaine que les services et
        publicités destinés à ce terminal correspondent bien à votre utilisation
        propre et non a celle d'un autre utilisateur de ce terminal.
        <br />
        <br />Le cas échéant, le partage avec d'autres personnes de l'utilisation de
        ce terminal et la configuration des paramètres du navigateur utilisé à l'égard
        des cookies, relèvent de votre libre choix et de votre responsabilité.
      </p>
      <h4>2.2. Cookies tiers</h4>
      <p>
        Lorsque vous naviguez sur le Site, des cookies émis par des sociétés tierces
        peuvent être placés par ces dernières sur votre terminal, sous réserve des
        choix que vous avez pu exercer antérieurement ou à tout moment, notamment au
        travers de la configuration des paramètres de votre logiciel de navigation,
        dans les conditions décrites à l'Article 2.5 ci-dessous. Ces cookies ont pour
        finalité d'identifier les Produits consultés sur le Site et de collecter des
        données de navigation afin de personnaliser l'offre publicitaire qui pourrait
        vous être présentée en dehors du Site. Les données recueillies par le biais de
        ces cookies sont partagées avec des tiers à des fins publicitaires. Dans le
        cadre de ce type de partenariat publicitaire, la société SAVE STORE
        peut donc être amenée à transmettre au partenaire concerné des données de
        navigation anonymes concernant les Produits consultés avec votre terminal lors
        de votre navigation sur le Site.
        <br />
        <br />L'émission et l'utilisation de cookies par des tiers sont soumises aux
        politiques de protection de la vie privée de ces tiers. La société SAVE
        STORE vous informe de l'objet des cookies dont la société SAVE
        STORE a connaissance et des moyens dont vous disposez pour effectuer
        des choix à l'égard de ces cookies et de leurs émetteurs respectifs.
      </p>
      <h4>
        2.3. Comment exprimer vos choix concernant les cookies directement via votre
        terminal ?
      </h4>
      <p>
        Lorsque vous naviguez sur le site de Save, vous acceptez expressement
        l'enregistrement de cookies dans votre terminal avec les paramètres appropriés
        de votre logiciel de navigation sur Internet. Vous acceptez aussi de nous
        envoyer les données que nous pouvons récolter sur votre navigation et les
        données que vous rentrez dans les formulaires. Sur ce point, nous vous
        invitons à consulter les conseils d'utilisation de ces paramètres, selon le
        navigateur utilisé, accessibles sur la page du site internet de la Commission
        Nationale de l'Informatique et des Libertés :
        <a
          href="https://www.google.com/url?q=http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/&amp;sa=D&amp;ust=1464346766863000&amp;usg=AFQjCNEAbXi8YfvERVyMaoSiXi_Ra2vVGQ"
          >http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/</a
        >.
        <br />
        <br />Vous pouvez également autoriser ou refuser l'enregistrement de cookies
        dans votre terminal (mais dans ce dernier cas, vous ne pourrez plus passer de
        commande sur le Site), en configurant votre navigateur à cette fin.
        <br />
        <br />Attention, la configuration de chaque navigateur est différente. Elle
        est décrite dans le menu d'aide de votre navigateur, qui vous permettra de
        savoir de quelle manière modifier vos souhaits en matière de cookies,
        notamment de la maniere suivante pour les navigateurs les plus couramment
        utilisés :
      </p>
      <ul>
        <li>
          Pour Internet Explorer™ :
          <a
            href="https://www.google.com/url?q=http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies&amp;sa=D&amp;ust=1464346766865000&amp;usg=AFQjCNE3qvtWKJk-p8x-2y5z_LYX0Ur8hA"
            >http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a
          >
        </li>
        <li>
          Pour Safari™ :
          <a
            href="https://www.google.com/url?q=http://www.apple.com/fr/privacy/use-of-cookies/&amp;sa=D&amp;ust=1464346766865000&amp;usg=AFQjCNGc6IKxhvnMroo6O6qTmgo_J0VRlA"
            >http://www.apple.com/fr/privacy/use-of-cookies/</a
          >
        </li>
        <li>
          Pour Chrome™ :
          <a
            href="https://www.google.com/url?q=http://support.google.com/chrome/bin/answer.py?hl%3Dfr%26hlrm%3Den%26answer%3D95647&amp;sa=D&amp;ust=1464346766866000&amp;usg=AFQjCNESHXFzBjFVYLuRxqhtyE_Y842DWw"
            >http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647</a
          >
        </li>
        <li>
          Pour Firefox™ :
          <a
            href="https://www.google.com/url?q=http://support.mozilla.org/fr/kb/Activer%2520et%2520d%25C3%25A9sactiver%2520les%2520cookies&amp;sa=D&amp;ust=1464346766867000&amp;usg=AFQjCNFwa5aFknM42vdXM3_fJfqEisG9Lg"
            >http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a
          >
        </li>
        <li>
          Pour Opéra™ :
          <a
            href="https://www.google.com/url?q=http://help.opera.com/Windows/10.20/fr/cookies.html&amp;sa=D&amp;ust=1464346766868000&amp;usg=AFQjCNEOSOoHhS1rpV9eldfJVB7z_QSwFQ"
            >http://help.opera.com/Windows/10.20/fr/cookies.html</a
          >
        </li>
      </ul>
    `,
    },
]

export default terms
