import React from 'react'
import styled from 'styled-components'

const BlockChild = styled.div`
  background: #ffffff;
  border-radius: 15px;
  overflow: auto;
`
interface Props {
  children: React.ReactNode
}

const Block: React.FC<Props> = ({ children }) => (
  <div>
    <BlockChild>{children}</BlockChild>
  </div>
)

const TermsContainer = styled.div`
  padding: 11px 20px;
  display: grid;
  gap: 13px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 24px 29px;
    grid-template-columns: minmax(min-content, 322px) 1fr;
    gap: 29px;
  }
`

export default Object.assign(TermsContainer, {
  Block,
})
