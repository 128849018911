import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'
import { MenuItem } from '../data/terms'

const Title = styled.h2`
  padding: 14px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px 10px 15px 10px;
    font-size: 20px;
    line-height: 24px;
  }
`

interface MenuLinkProps {
  active?: 'true' | 'false'
}

const link = css<MenuLinkProps>`
  display: block;
  padding: 11px 24px 12px 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  border-top: 1px solid #f3f3f3;

  ${({ active }) => {
    return (
      active &&
      css`
        box-shadow: inset 4px 0 0 0 ${({ theme }) => theme.colors.secondary};
      `
    )
  }}
`

const MenuGatsbyLink = styled(Link)<MenuLinkProps>`
  ${link}
`

const MenuDocLink = styled.a<MenuLinkProps>`
  ${link}
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;

  & > img {
    margin-right: 11px;
  }
`

interface TermsMenuProps {
  currentPath: string
  items: MenuItem[]
}

const TermsMenu: React.FC<TermsMenuProps> = ({ currentPath, items }) => (
  <div>
    <Title>Termes et conditions</Title>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <MenuGatsbyLink
            to={item.url}
            active={item.url === currentPath ? 'true' : undefined}
          >
            {item.label}
          </MenuGatsbyLink>
        </li>
      ))}
      <li>
        <MenuDocLink
          download
          href="/themes/save/media/docs/dossier_cgv_Save_2022.pdf"
        >
          <img src="/images/media/Download.svg" alt={"Download"} width={19} height={19} />
          <span>Dossier CGV</span>
        </MenuDocLink>
      </li>
    </ul>
  </div>
)

export default TermsMenu
