import { PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import terms from '../data/terms'
import TermsContainer from '../components/TermsContainer'
import TermsTitle from '../components/TermsTitle'
import TermsContent from '../components/TermsContent'
import TermsMenu from '../components/TermsMenu'
import { Helmet } from 'react-helmet'

const useTermsTitle = (path: string) => {
  return useMemo(() => {
    const item = terms.find((x) => x.url === path)
    return item?.label ?? 'Termes et conditions'
  }, [])
}

const useTermsContent = (path: string) => {
  return useMemo(() => {
    const item = terms.find((x) => x.url === path)
    return item?.content ?? ''
  }, [])
}

const useTermsMetaDesc = (path: string) => {
  return useMemo(() => {
    const item = terms.find((x) => x.url === path)
    return item?.meta_desc
  }, [])
}

const TermsPage: React.FC<PageProps> = ({ location }) => {
  const title = useTermsTitle(location.pathname)
  const content = useTermsContent(location.pathname)
  const metaDesc = useTermsMetaDesc(location.pathname)

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDesc ?? 'Retrouvez nos conditions générales'}
        />
      </Helmet>

      <TermsTitle>{title}</TermsTitle>

      <TermsContainer>
        <TermsContainer.Block>
          <TermsMenu currentPath={location.pathname} items={terms} />
        </TermsContainer.Block>
        <TermsContainer.Block>
          <TermsContent content={content} />
        </TermsContainer.Block>
      </TermsContainer>
    </>
  )
}

export default TermsPage
